$theme-colors: (
  "primary": #137ac3,
  "danger": #d11554,
  "secondary": #6f6f6f,  
  "success": #198754,
  "light": #f8f9fa,
  "dark": #212529,
  "warning": #fcc34c,
  //same as adg purple color
  "info": #765cb2  
  //old info color -  #0dcaf0

);


$font-family-sans-serif: 'Poppins', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$body-family-base: 'Poppins', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$btn-padding-x: 0.80rem;
$btn-padding-y: 0.60rem;
$btn-font-size: 0.90rem;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
